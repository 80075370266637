import { defineNuxtRouteMiddleware } from '#imports';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { $i18n } = useNuxtApp();
  const session = useSession();
  const selectedLang = session.get.value?.language;

  const defaultLanguage = useRequestHeader('accept-language')?.slice(0,2);
  $i18n.setLocale(selectedLang || defaultLanguage || 'en');
});

